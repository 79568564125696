import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SampleForm from "../components/form/form"

const AboutPage = () => {
  return (
    <Layout>
      <SEO title="About" />
      <div className="page-headline mt-5 about-bg">
        <div className="container">
          <div className="section-heading text-center ">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <h1 className="font-weight-bold text-uppercase text-white mb-4">
                  <strong>ABOUT THE COMPANY</strong>
                </h1>
                <p className="text-white" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-content pb-0">
        <section className="about-section" id="about-section-1">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="container">
                  <div className="bg-purple-content" />
                  <div className="content">
                    <h1 className="text-white">
                      <strong>ABOUT US</strong>
                    </h1>
                    <p className="text-white">
                      We infuse our edibles with a 100% hemp-based, proprietary
                      blend oil. Our oil is extracted using subcritical (low
                      temperature and pressure) CO2. This method is non-toxic
                      and energy efficient. We take pride in knowing our
                      products are the best tasting and made using the best
                      sources and methods.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div id="wholesale" />
          </div>

          <div id="about-bg" className="col-md-7" />
        </section>

        <section className="about-section" id="about-section-2">
          <div className="container">
            <div className="text-center">
              <div className="row">
                <div className="col-md-10 mx-auto">
                  <h1 className="text-white">
                    <strong>WHOLESALE</strong>
                  </h1>
                  <p className="text-white">
                    If you are interested in buying our products at wholesale
                    pricing, please fill out the contact form below and a member
                    of our team will be in touch shortly. We currently have a
                    large list of wholesalers and would love for you to be a
                    part of our team! We also have a growing White-Label CBD
                    Business, if you are interested in our white-label products,
                    please let us know and we will show you our capabilities and
                    ranges.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <div className="col-md-3 text-center">
                <img src={require("../images/Mask-Group-19.svg")} alt="" />
              </div>
              <div className="col-md-3 text-center">
                <img src={require("../images/Mask-Group-13.svg")} alt="" />
              </div>
              <div className="col-md-3 text-center">
                <img src={require("../images/Mask-Group-14.svg")} alt="" />
              </div>
              <div className="col-md-3 text-center">
                <img src={require("../images/Mask-Group-15.svg")} alt="" />
              </div>
            </div>
          </div>
        </section>
        <div id="start-your-business" />
        <section className="about-section" id="about-section-3">
          <div className="container">
            <div className="row">
              <div className="col-md-7 ml-auto">
                <div className="container">
                  <div className="bg-purple-content" />
                  <div className="content">
                    <h1 className="text-white">
                      <strong>START YOUR OWN BUSINESS</strong>
                    </h1>
                    <p className="text-white">
                      If you are interested in starting your own CBD Business,
                      you can now do so with our help! We will send you a
                      variety of Heavenly-Candy CBD infused items, along with
                      everything else you need to get started. Your started kit
                      will include:
                    </p>
                    <ul className="text-white">
                      <li>A banner</li>
                      <li>500 honey sticks</li>
                      <li>Assorted candy selection</li>
                      <li>50 tri-fold flyers with holder</li>
                    </ul>
                    <p className="text-white">
                      Earn up to $1200 selling Heavenly Candy for the cost of
                      $499.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="about-bg" className="col-md-7" />
        </section>
        <section className="about-section" id="about-section-4">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mx-auto">
                <div className="container">
                  <div className="content text-center">
                    <h1 className="text-white">
                      <strong>CONTACT US FOR WHOLESALE</strong>
                    </h1>
                    <p className="text-white mb-5">
                      If you are interested in buying our products at wholesale
                      pricing, please fill out the contact form below and a
                      member of our team will be in touch shortly.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-10 mx-auto text-center">
                <SampleForm
                  form_name="Newsletter Form"
                  form_id="5bcd42f86b63453b251972bc"
                  form_classname="form-newsletter"
                >
                  <div className="form-row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          id="firstName"
                          required=""
                        />
                        <label htmlFor="firstName">First Name</label>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          className="form-control"
                          type="text"
                          id="lastName"
                          required=""
                        />
                        <label htmlFor="lastName">Last Name</label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="email"
                      id="homeEmail"
                      required=""
                    />
                    <label htmlFor="homeEmail">Email address</label>
                  </div>
                  <div className="form-group">
                    <input className="form-control" id="phone" type="tel" />
                    <label htmlFor="phone">Phone (optional)</label>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control h-auto"
                      id="message"
                      required=""
                      rows="4"
                    />
                    <label htmlFor="message">Message</label>
                  </div>
                  <div className="form-group">
                    <div className="webriq-recaptcha" />
                  </div>
                  <div className="form-group text-center mt-5 btn-container">
                    <button
                      className="button-gold font-weight-bold"
                      type="submit"
                    >
                      CONTACT US
                    </button>
                  </div>
                </SampleForm>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default AboutPage
